import React from "react";
import VideoCall from "../helpers/simple-peer";
import io from "socket.io-client";
import {
  getDisplayStream,
  getAudioStream,
  getVideoStream,
  getAllStream,
} from "../helpers/media-access";
import {
  Icon,
  Label,
  Image,
  Transition,
  Button,
  Card,
  Grid,
  Segment,
} from "semantic-ui-react";
import * as Sentry from "@sentry/browser";
import VideoStreamMerger from "../helpers/VideoStreamMerger";
import QRious from "qrious";
import {
  mobileVendor,
  mobileModel,
  deviceType,
  osName,
  osVersion,
  browserName,
  browserVersion,
  isSafari,
  isIOS,
  isTablet,
  isMobile,
  isAndroid,
} from "react-device-detect";
import VideoItem from "./videoItem";
import RatingPopup from "./RatingPopup";

import "../styles/video.css";

var merger = null;
var mediaRecorder1;
var mediaRecorder2;
var R_WIDTH = 320;
var R_HEIGH = 240;
var R_WIDTH_PAD = 8;

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

let userId = null;

class Video extends React.Component {
  constructor() {
    super();
    this.state = {
      localStream: null,
      initiator: false,
      peers: {},
      remotePeerName: [],
      myName: "",
      hn: "",
      isRec: false,
      recordMimeType: "",
      full: false,
      connecting: false,
      waiting: true,
      connected: false,
      socket: null,
      roomId: "",
      remoteStreamList: [],
      showerr: false,
      errHeader: "There was some errors",
      errMsg: "",
      micState: false,
      audioStream: {},
      camState: false,
      shareScreenOn: false,
      pipOn: false,
      micLoading: false,
      webcamLoading: false,
      iframeDetect: false,
      inbound_qos: "",
      outbound_qos: "",
      ipText: "",
      geekStat: false,
      allowP2P: true,
      isShareScreen: false,
      isGroupChat: false,
      columnSize: 2,
      stackable: false,
      shareScreenStreamId: "",
      shareScreenStream: {},
      closeOverriding: false,
      ratingPopupOpen: false,
    };
  }

  videoCall = new VideoCall();

  stopMediaRecorder = (_mediaRecorder) => {
    if (_mediaRecorder) {
      if (_mediaRecorder.state === "recording") {
        _mediaRecorder.stop();
      }
    }
  };

  beforeUnload = () => {
    this.stopMediaRecorder(mediaRecorder1);
    this.stopMediaRecorder(mediaRecorder2);

    if (merger) {
      merger.destroy();
    }
  };

  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      // ev.preventDefault();
      this.beforeUnload();
      return true;
    });
  };

  columnSizeCal = (peer_count) => {
    if (peer_count < 1) {
      this.setState({ columnSize: 1 });
    } else if (peer_count >= 3)
      this.setState({ columnSize: Math.ceil(peer_count / 2) });
    else this.setState({ columnSize: peer_count });

    if (isMobile && peer_count >= 3) {
      this.setState({ stackable: false });
    } else {
      this.setState({ stackable: true });
    }
  };

  componentDidMount() {
    this.setupBeforeUnloadListener();

    if (window.location !== window.parent.location) {
      // The page is in an iframe
      this.setState({ iframeDetect: true });
    } else {
      // The page is not in an iframe
      this.setState({ iframeDetect: false });
    }
    const socket = io(process.env.REACT_APP_SIGNALING_SERVER);
    this.setState({ socket: socket });
    this.setState({ socket });
    const { roomId } = this.props.match.params;
    this.setState({ roomId: roomId });
    let params = new URLSearchParams(this.props.location.search);
    this.setState({ myName: params.get("name") ?? "-" });
    this.setState({ hn: params.get("hn") ?? "-" });
    // this.setState({ isRec: params.get("isRec") === "true" });
    // this.setState({ allowP2P: params.get("allowP2P") === "true" });
    this.setState({
      closeOverriding: params.get("closeOverriding") === "true",
    });
    this.setState({ geekStat: params.get("geekStat") === "true" });

    var referrer = document.referrer ?? "-";
    try {
      var hw_info = {
        mobileVendor: mobileVendor,
        mobileModel: mobileModel,
        deviceType: deviceType,
      };
      var sw_info = {
        osName: osName,
        osVersion: osVersion,
        browserName: browserName,
        browserVersion: browserVersion,
      };
      socket.emit("info", {
        roomId: roomId,
        name: this.state.myName,
        referrer: referrer,
        hw_info: hw_info,
        sw_info: sw_info,
      });
    } catch (e) {
      console.log("error", e);
    }

    getAllStream()
      .then((stream) => {
        // Auto join after get webcam

        this.setState({ localStream: stream });
        this.setState({ camState: true });
        this.setState({ micState: true });
        var randInt = getRandomInt(100, 3000);
        if (this.isMobile()) {
          randInt = getRandomInt(1000, 2000);
        }
        setTimeout(() => {
          window.top.postMessage(
            JSON.stringify({
              error: false,
              message: "stop_pip",
            }),
            "*"
          );

          socket.emit("join", {
            roomId: roomId,
            name: this.state.myName,
            referrer: referrer,
            iosNative: false,
          });
        }, randInt);
      })
      .catch((err) => {
        console.log("Media-Access: " + err.message);
        Sentry.captureException(err);
        this.setState({
          showerr: true,
          errMsg: "Media-Access: " + err.message,
        });
        this.setState({ webcamLoading: false });
        // setTimeout(() => {
        //   this.setState({ showerr: false });
        //   window.location.reload();
        // }, 10000);

        var referrer = document.referrer ?? "-";
        socket.emit("join", {
          roomId: roomId,
          name: this.state.myName,
          referrer: referrer,
          iosNative: false,
        });
      });

    // socket.on("init", (data) => {
    //   console.log("socket.on init", data)

    //   // component.setState({ initiator: true });
    // });
    socket.on("ready", (data) => {
      console.log("socket.on ready", data);
      userId = data.userId;

      // component.enter(roomId);
      window.top.postMessage(
        JSON.stringify({
          error: false,
          message: "ready",
        }),
        "*"
      );

      socket.emit("ready", { room: roomId, userId });
      console.log("ready ", this.state.roomId);
    });
    socket.on("users", ({ initiator, users, iosNative }) => {
      this.setState({ connecting: true, waiting: false });
      console.log("socket.on", initiator, users);

      window.top.postMessage(
        JSON.stringify({
          error: false,
          users_count: users.length,
          message: "ready",
        }),
        "*"
      );

      if (users.length <= 1 && !isIOS) {
        this.setState({ isRec: true });
        var _merger = this.newMerger(false);
        // let latestStream = null;
        // this.state.remoteStreamList.forEach((item, i) => {
        //   console.log(item)
        //   if (item.active === true) {
        //     latestStream = item;
        //   }
        // });
        // _merger = this.mergerProcessing(
        //   this.state.localStream,
        //   latestStream,
        //   false,
        //   false
        // );

        setTimeout(() => {
          if (_merger !== undefined) {
            this.recorderProcessing(
              _merger.result,
              this.state.roomId,
              mediaRecorder1,
              _merger
            );
          }
        }, 3000);
      }

      this.columnSizeCal(users.length - 1);

      if (isMobile) {
        if (users.length > 2) {
          this.setState({ isGroupChat: true });
        } else {
          this.setState({ isGroupChat: false });
        }
      } else {
        if (users.length > 3) {
          this.setState({ isGroupChat: true });
        } else {
          this.setState({ isGroupChat: false });
        }
      }

      Object.keys(users.sockets)
        .filter((sid) => !this.state.peers[sid] && sid !== userId)
        .forEach((sid) => {
          const isInitiator = userId === initiator;
          // const isInitiator = true
          const peer = this.videoCall.init(
            this.state.localStream,
            isInitiator || iosNative,
            this.state.allowP2P
          );

          peer["remoteStream"] = [];

          console.log("is initiator: ", isInitiator);
          console.log("remote user is iosNative?", iosNative);
          // peer._debug = console.log;
          console.log("data channel: " + peer.channelName);

          peer.on("connect", () => {
            console.log("Connected !!!");

            this.setState({ connected: true });
            this.setState({ full: false, waiting: false, connecting: false });
            // Set myName to remote peer
            const data = {
              type: "setName",
              value: { userId: userId, name: this.state.myName },
            };
            socket.emit("update_peers", this.state.myName);
            peer.send(JSON.stringify(data));

            // peer._pc.getSenders().forEach((sender0, i) => {
            //   // const sender0 = peer._pc.getSenders()[0]; //video
            //   const parameters0 = sender0.getParameters();

            //   if (!parameters0.encodings) {
            //     parameters0.encodings = [{}];
            //   }

            //   parameters0.encodings[0].maxBitrate = 4 * 1000 * 100; // 4 Mbps
            //   parameters0.encodings[0].priority = "high";
            //   parameters0.encodings[0].networkPriority = "very-low";

            //   sender0
            //     .setParameters(parameters0)
            //     .then(() => {
            //       console.log("set video bitrate ok");
            //     })
            //     .catch((e) => console.error(e));
            // })

            if (this.state.shareScreenOn) {
              this.notifyShareScreen(true, this.state.shareScreenStreamId);
              peer.addStream(this.state.shareScreenStream);
            }
          });

          peer.on("signal", (data) => {
            console.log("create signal", data);

            // if (data.sdp) {
            //   data.sdp = data.sdp.replace(
            //     "useinbandfec=1",
            //     "useinbandfec=1; stereo=1; maxaveragebitrate=256000"
            //   );
            //   var sdpArray = data.sdp.split("\n");
            //   data.sdp.split("\n").forEach((_sdp, i) => {
            //     if (_sdp.startsWith("m=video")) {
            //       sdpArray.splice(i + 2, 0, "b=AS:10000");
            //     }
            //   });
            //   data.sdp = sdpArray.join("\n");
            // }

            const signal = {
              room: roomId,
              userId: sid,
              name: this.state.myName,
              desc: data,
            };
            // Send offer message
            this.state.socket.emit("signal", signal);
          });
          peer.on("close", () => {
            console.log("peer closed");
            socket.off("callAccepted");
          });
          peer.on("stream", (stream) => {
            console.log("got new stream", sid, stream);

            if (this.state.shareScreenStreamId === stream.id) {
              this.setState({ shareScreenStream: stream });
              this.setState({ isShareScreen: true });
              this.newMerger(false);
              return;
            }

            const peersTemp = { ...this.state.peers };
            console.log(peersTemp);
            if (peersTemp[sid].remoteStream.indexOf(stream) === -1) {
              // peersTemp[sid].remoteStream = [] // clear all prev stream
              peersTemp[sid].remoteStream.push(stream);
            }
            this.setState({ peers: peersTemp });

            let tmpList = this.state.remoteStreamList;
            tmpList.push(stream);
            this.setState({ remoteStreamList: tmpList });

            this.newMerger(false);

            // if (stream.getAudioTracks().length > 0) {
            //   console.log("found audio track",stream.getVideoTracks()[0]);
            //   // this.remoteAudio.srcObject = stream;
            // }
            // if (stream.getVideoTracks().length > 0) {
            //   console.log("found video track",stream.getVideoTracks()[0]);
            //   // this.remoteVideo.srcObject = stream;
            // }

            // if (this.state.isRec === true) {
            //   try {
            //     var _merger = this.mergerProcessing(
            //       this.state.localStream,
            //       stream,
            //       true,
            //       false
            //     );
            //     this.recorderProcessing(
            //       _merger.result,
            //       this.state.roomId,
            //       mediaRecorder1,
            //       _merger
            //     );
            //   } catch (e) {
            //     console.error("recorderProcess err: ", e);
            //     this.setState({ isRec: false });
            //   }
            // }

            if (this.state.remoteStreamList.length <= 1 && this.state.isRec) {
              try {
                this.getStat();
              } catch (e) {
                console.log("0getStat", e);
              }
            }
          });
          peer.on("error", function (err) {
            console.log(err);
            Sentry.captureException(err);
          });
          peer.on("data", (data) => {
            try {
              data = JSON.parse(data);
            } catch (e) {
              data = { type: "", value: data };
            }

            console.log("data: " + JSON.stringify(data));
            if (data.type === "setName") {
              console.log(JSON.stringify(data.value));
              const peersNameTemp = { ...this.state.remotePeerName };
              peersNameTemp[data.value.userId] = data.value.name;
              this.setState({ remotePeerName: peersNameTemp });
              console.log(peersNameTemp);

              //HACK for webrtc status stuck on connecting...
              this.setState({ connected: true });
              this.setState({ full: false, waiting: false, connecting: false });
            }
            if (data.type === "removeVideo") {
              console.log("got remove video stream...", data);
              const peersTemp = { ...this.state.peers };
              const removeIdx = peersTemp[data.userId].remoteStream.findIndex(
                (s) => s.id === data.value
              );
              if (removeIdx > -1) {
                console.log(removeIdx, peersTemp[data.userId].remoteStream);
                peersTemp[data.userId].remoteStream.splice(removeIdx, 1);
              }
              this.setState({ peers: peersTemp });
            }

            if (data.type === "check_video") {
              //implement check video
              if (peer.remoteStream.length === 0) {
                const lost_video = {
                  type: "lost_video",
                  value: this.state.localStream,
                };
                peer.send(JSON.stringify(lost_video));
              }
            }

            if (data.type === "lost_video") {
              getAllStream()
                .then((stream) => {
                  this.setState({ localStream: stream });
                  this.setState({ camState: true });
                  this.setState({ micState: true });
                  this.attachVideoStream(stream);
                })
                .catch((err) => {
                  console.log("Media-Access: " + err.message);
                  Sentry.captureException(err);
                  this.setState({
                    showerr: true,
                    errMsg: "Media-Access: " + err.message,
                  });
                  this.setState({ webcamLoading: false });
                });
            }

            if (data.type === "sharescreen") {
              if (data.isShare)
                this.setState({ shareScreenStreamId: data.value });
              else
                this.setState({
                  shareScreenStreamId: "",
                  isShareScreen: false,
                  shareScreenStream: {},
                });
            }
          });

          const peersTemp = { ...this.state.peers };
          peersTemp[sid] = peer;
          this.setState({ peers: peersTemp });
        });
    });

    socket.on("signal", ({ userId, signal }) => {
      console.log("socket.on  signal userId", userId, "signal", signal);
      // console.log(this.state.peers)
      const peer = this.state.peers[userId];
      if (peer) {
        if (peer.destroyed == false) {
          peer.signal(signal);
        }
      } else {
        Object.keys(this.state.peers).forEach((key) => {
          if (this.state.peers[key].destroyed == false) {
            this.state.peers[key].signal(signal);
          }
        });
      }
    });

    socket.on("disconnected", ({ userId }) => {
      console.log("on disconnected " + userId);
      console.log(this.state.peers);

      const peersTemp = { ...this.state.peers };
      delete peersTemp[userId];
      this.setState({ peers: peersTemp });

      const count = Object.keys(peersTemp).length;
      console.log(peersTemp, count);

      this.columnSizeCal(count);

      if (isMobile) {
        if (count >= 2) {
          this.setState({ isGroupChat: true });
        } else {
          this.setState({ isGroupChat: false });
        }
      } else {
        if (count >= 3) {
          this.setState({ isGroupChat: true });
        } else {
          this.setState({ isGroupChat: false });
        }
      }

      this.newMerger(false);

      // if (this.state.isRec === true) {
      //   socket.emit("stoprec", "stoprec");
      //   socket.emit("stoprec2", "stoprec2");
      //   try {
      //     this.stopMediaRecorder(mediaRecorder1);
      //     this.stopMediaRecorder(mediaRecorder2);

      //     this.state.remoteStreamList.forEach((item, i) => {
      //       merger.removeStream(item);
      //     });
      //     merger.removeStream(this.state.localStream);
      //     merger.destroy();
      //   } catch (e) {
      //     console.log("error", e);
      //   }
      // }
      // component.setState({
      //   initiator: true,
      //   connected: false,
      //   full: false,
      //   waiting: true,
      //   connecting: false,
      // });

      // this.stopAudio();
      // this.stopWebcam();
      // this.stopShareScreen();

      // this.remoteVideo.srcObject = null;

      // const users_count = Object.keys(this.state.peers).length
      // window.top.postMessage(
      //   JSON.stringify({
      //     error: false,
      //     users_count: users_count,
      //     message: "end_call",
      //   }),
      //   "*"
      // );

      // window.location.reload();

      return false;
    });

    socket.on("message", function (m) {
      console.log("recv server message", m);
    });
    // socket.on('fatal',function(m){
    //   console.error('Fatal ERROR: unexpected:'+m);
    // });

    socket.on("on_record_done", (data) => {
      console.log("on_record_done ", data);
      window.top.postMessage(
        JSON.stringify({
          error: false,
          message: "on_record_done",
        }),
        "*"
      );
    });
  }
  setAudioLocal() {
    if (
      this.state.localStream &&
      this.state.localStream.getAudioTracks().length > 0
    ) {
      this.state.localStream.getAudioTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
    }
    this.setState({
      micState: !this.state.micState,
    });
  }
  setVideoLocal() {
    if (this.state.shareScreenOn) {
      this.stopShareScreen();
      return;
    }

    console.log("Local Stream:", this.state.localStream);
    if (
      this.state.localStream &&
      this.state.localStream.getVideoTracks().length > 0
    ) {
      console.log("Video Tracks:", this.state.localStream.getVideoTracks());
      this.state.localStream.getVideoTracks().forEach((track) => {
        console.log("Toggling track:", track);
        track.enabled = !track.enabled;
      });
    }
    this.setState({
      camState: !this.state.camState,
    });
  }

  attachVideoStream(stream) {
    Object.keys(this.state.peers).forEach((key) => {
      if (stream) {
        let peer = this.state.peers[key];
        console.log("attachVideoStream " + stream.id, stream);
        console.log("addStream " + key, peer);
        peer.addStream(stream);
      }
    });
  }

  startPip = async (evt) => {
    // const vids = document.querySelectorAll( "video" );
    // console.log(vids);

    if (!("pictureInPictureEnabled" in document) || isAndroid) {
      this.setState({
        showerr: true,
        errMsg: "The Picture-in-Picture API is not available.",
      });
      window.top.postMessage(
        JSON.stringify({
          error: false,
          message: "stop_pip",
        }),
        "*"
      );
      setTimeout(() => {
        this.setState({ showerr: false });
      }, 5000);
      return;
    }

    const video = document.createElement("video");

    let latestStream = null;
    this.state.remoteStreamList.forEach((item, i) => {
      console.log(item);
      if (item.active === true) {
        latestStream = item;
      }
    });

    var _merger = null;
    if (this.state.isGroupChat || latestStream === null) {
      _merger = this.newMerger(false);
    } else {
      _merger = this.mergerProcessing(
        this.state.localStream,
        latestStream,
        false,
        true // mute
      );
      // this.updateVideoSize();
    }

    video.srcObject = _merger.result;

    video.addEventListener("enterpictureinpicture", (e) => {
      window.top.postMessage(
        JSON.stringify({
          error: false,
          message: "start_pip",
        }),
        "*"
      );
      this.setState({ pipOn: true });
    });

    video.addEventListener("leavepictureinpicture", (e) => {
      window.top.postMessage(
        JSON.stringify({
          error: false,
          message: "stop_pip",
        }),
        "*"
      );
      this.setState({ pipOn: false });
    });

    await video.play();
    if (isSafari) {
      video.webkitSetPresentationMode("picture-in-picture");
    } else {
      video.requestPictureInPicture();
    }
  };

  stopPip() {
    alert("stop");
  }

  stopShareScreen() {
    console.log("stop shareScreen...");

    if (this.state.shareScreenStream) {
      Object.keys(this.state.peers).forEach((key) => {
        if (this.state.connected) {
          try {
            this.state.peers[key].removeStream(this.state.shareScreenStream);
          } catch (e) {
            console.log("error", e);
          }
          console.log("remove share ss stream");
        }
      });
      this.notifyShareScreen(false, this.state.shareScreenStreamId);
      this.setState({ shareScreenStream: {}, isShareScreen: false });
    }
    this.setState({ shareScreenOn: false });

    // this.localVideo.srcObject = null;
    // getAllStream()
    //   .then((stream) => {
    //     this.setState({ localStream: stream });
    //     this.localVideo.srcObject = stream;
    //     this.setState({ camState: true });
    //     this.setState({ micState: true });
    //     this.attachVideoStream(stream);
    //   })
    //   .catch((err) => {
    //     console.log("Media-Access: " + err.message);
    //     Sentry.captureException(err);
    //     this.setState({
    //       showerr: true,
    //       errMsg: "Media-Access: " + err.message,
    //     });
    //     this.setState({ webcamLoading: false });
    //   });

    let socket = this.state.socket;
    socket.emit("stoprec2", "stoprec2");
    try {
      this.stopMediaRecorder(mediaRecorder2);
    } catch (e) {
      console.log("error", e);
    }
  }

  notifyShareScreen = (isShare, id) => {
    Object.keys(this.state.peers).forEach((key) => {
      const payload = {
        type: "sharescreen",
        value: id,
        isShare: isShare,
      };
      this.state.peers[key].send(JSON.stringify(payload));
    });
  };

  getShareScreen() {
    getDisplayStream()
      .then((stream) => {
        // Object.keys(this.state.peers).forEach((key)=>{
        //   this.state.peers[key].removeStream(this.state.localStream);
        // });

        stream.oninactive = () => {
          console.log("inactive share screen");
          this.stopShareScreen();
        };

        this.setState({ shareScreenStream: stream, isShareScreen: true });

        this.setState({ shareScreenStreamId: stream.id });
        this.notifyShareScreen(true, stream.id);

        this.attachVideoStream(stream);
        this.setState({ shareScreenOn: true });
        this.newMerger(false);

        // if (this.state.isRec === true) {
        //   try {
        //     this.recorderProcessing2(stream, this.state.roomId + "_screen");
        //   } catch (e) {
        //     console.error("recorderProcess err: ", e);
        //     this.setState({ isRec: false });
        //   }
        // }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        if (err.message !== "Permission denied") {
          this.setState({ showerr: true, errMsg: err.message });
          setTimeout(() => {
            this.setState({ showerr: false });
          }, 5000);
        }
      });
  }

  isMobile() {
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile;
  }

  isAndroid() {
    let isAndroid = /Android/i.test(navigator.userAgent);
    return isAndroid;
  }

  // _isSafari() {
  //   let isSafari = /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);
  //   return isSafari;
  // }

  isChrome() {
    let isChrome = /Chrome/i.test(navigator.userAgent);
    return isChrome;
  }

  updateVideoSize() {
    if (merger.started === false) {
      return;
    }
    var potrait = false;
    // if (this.remoteVideo.videoWidth > this.remoteVideo.videoHeight) {
    //   potrait = false;
    // }
    if (potrait) {
      merger.setOutputSize(720 + R_WIDTH + R_WIDTH_PAD, 960);
    } else {
      merger.setOutputSize(1280 + R_WIDTH + R_WIDTH_PAD, 960);
    }
  }

  closeRatingPopup() {
    this.setState({ ratingPopupOpen: false });
    const users_count = Object.keys(this.state.peers).length;
    window.top.postMessage(
      JSON.stringify({
        error: false,
        users_count: users_count,
        message: "end_call",
      }),
      "*"
    );
    window.onbeforeunload = null;
    window.open("about:blank", "_self");
    window.close();
  }

  newMerger(isMute = false) {
    if (this.state.localStream === null) {
      return undefined;
    }

    if (merger === null) {
      merger = new VideoStreamMerger({
        width: 1280, // Width of the output video
        height: 720, // Height of the output video
        fps: 10,
        clearRect: true, // Clear the canvas every frame
        audioContext: null, // Supply an external AudioContext (for audio effects)
      });
    }

    merger.removeStream(this.state.localStream);
    this.state.remoteStreamList.forEach((st, i) => {
      if (st.active === true) merger.removeStream(st);
    });

    this.state.remoteStreamList.forEach((st, i) => {
      console.log(i + " " + st.id);
      merger.addStream(st, {
        x: R_WIDTH + R_WIDTH_PAD,
        y: 0,
        width: merger.width - R_WIDTH,
        height: merger.height,
        mute: isMute,
      });
    });
    merger.addStream(this.state.localStream, {
      x: 0,
      y: 0,
      width: R_WIDTH,
      height: R_HEIGH,
      mute: isMute,
    });

    if (this.state.isShareScreen) {
      merger.addStream(this.state.shareScreenStream, {
        x: 0,
        y: 0,
        width: 1280,
        height: 720,
        mute: true,
      });
    }

    if (!merger.started) merger.start();

    // this.updateVideoSize()
    return merger;
  }

  mergerProcessing(localStream, remoteStream, info = false, isMute = false) {
    merger = new VideoStreamMerger({
      width: 1280, // Width of the output video
      height: 720, // Height of the output video
      fps: 15, // Video capture frames per second
      clearRect: true, // Clear the canvas every frame
      audioContext: null, // Supply an external AudioContext (for audio effects)
    });

    var qr = new QRious();
    qr.background = "white";
    qr.foreground = "black";
    qr.level = "H";
    qr.padding = 10;
    qr.size = R_WIDTH;
    qr.value = "" + window.location.href;

    merger.addStream(remoteStream, {
      x: R_WIDTH + R_WIDTH_PAD,
      y: 0,
      width: merger.width - R_WIDTH,
      height: merger.height,
      mute: isMute,
      // draw: (ctx, frame, done) => {
      //   var dateTime = new Date().toLocaleString();
      //   ctx.font = "20px Arial";
      //   ctx.fillStyle = "white";
      //   if (info) {
      //     ctx.drawImage(qr.image, R_WIDTH_PAD, merger.height / 2);
      //   }
      //   ctx.drawImage(
      //     frame,
      //     R_WIDTH + R_WIDTH_PAD,
      //     0,
      //     merger.width,
      //     merger.height
      //   );
      //   if (info) {
      //     ctx.fillText(this.state.inbound_qos, R_WIDTH_PAD, merger.height - 92);
      //     ctx.fillText(
      //       this.state.outbound_qos,
      //       R_WIDTH_PAD,
      //       merger.height - 72
      //     );
      //     ctx.fillText(document.referrer, R_WIDTH_PAD, merger.height - 52);
      //     ctx.fillText(dateTime, R_WIDTH_PAD, merger.height - 32);
      //     ctx.fillText(navigator.userAgent, R_WIDTH_PAD, merger.height - 12);
      //   }
      //   done();
      // },
    });
    merger.addStream(localStream, {
      x: 0,
      y: 0,
      width: R_WIDTH,
      height: R_HEIGH,
      mute: isMute,
    });
    merger.start();
    // this.mergeVideo.srcObject = merger.result;

    return merger;
  }

  recorderProcessing(stream, filename, _mediaRecorder, _merger) {
    let socket = this.state.socket;

    socket.emit("startrec", filename);

    var options = {
      mimeType: "video/webm;codecs=h264;",
      videoBitsPerSecond: 2000000,
    }; //2Mbps

    if (MediaRecorder.isTypeSupported("video/webm;codecs=h264")) {
      options = {
        mimeType: "video/webm; codecs=h264",
        videoBitsPerSecond: 2000000,
      };
    } else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
      options = {
        mimeType: "video/webm; codecs=vp9",
        videoBitsPerSecond: 2000000,
      };
    } else {
      options = {
        mimeType: "video/webm; codecs=vp8",
        videoBitsPerSecond: 2000000,
      };
    }

    if (isSafari || isIOS) {
      options = { mimeType: "video/mp4;" };
    }

    this.setState({ recordMimeType: JSON.stringify(options) });

    _mediaRecorder = new MediaRecorder(stream, options);
    // _mediaRecorder = new MediaRecorder(stream);
    _mediaRecorder.start(400); //emit binarystream every 400ms

    _mediaRecorder.onstop = (e) => {
      console.log(e);
      // if (_merger) {
      //   _merger.destroy();
      // }
    };

    _mediaRecorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        socket.emit("binarystream", e.data);
      }
    };

    // setTimeout(() => {
    //   this.updateVideoSize();
    // }, 3000);

    _mediaRecorder.onerror = (e) => {
      // if (_merger) {
      //   _merger.destroy();
      // }
      console.error("_mediaRecorder err: ", e);
    };
  }

  // recorderProcessing2(stream, filename) {
  //   let socket = this.state.socket;
  //   socket.emit("startrec2", filename);

  //   var options = {
  //     mimeType: "video/webm;codecs=h264;",
  //     videoBitsPerSecond: 2000000,
  //   }; //2Mbps

  //   if (MediaRecorder.isTypeSupported("video/webm;codecs=h264")) {
  //     options = {
  //       mimeType: "video/webm; codecs=h264",
  //       videoBitsPerSecond: 2000000,
  //     };
  //   } else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
  //     options = {
  //       mimeType: "video/webm; codecs=vp9",
  //       videoBitsPerSecond: 2000000,
  //     };
  //   } else {
  //     options = {
  //       mimeType: "video/webm; codecs=vp8",
  //       videoBitsPerSecond: 2000000,
  //     };
  //   }

  //   if (isSafari || isIOS) {
  //     options = { mimeType: "video/mp4;" };
  //   }

  //   mediaRecorder2 = new MediaRecorder(stream, options);
  //   mediaRecorder2.start(400); //emit binarystream every 400ms

  //   mediaRecorder2.onstop = (e) => {
  //     console.log(e);
  //   };

  //   mediaRecorder2.ondataavailable = (e) => {
  //     if (e.data.size > 0) {
  //       socket.emit("binarystream2", e.data);
  //     }
  //   };

  //   mediaRecorder2.onerror = (e) => {
  //     console.error("mediaRecorder2 err: ", e);
  //   };
  // }

  calStat = (inVideo, outVideo, codecList, remoteInBoundRTP) => {
    var reason = "none";
    var outCodec = "-";
    var outCodecObj = codecList.filter(
      (codec) => codec.id === outVideo.codecId
    )[0];
    if (outCodecObj !== undefined) {
      outCodec = outCodecObj.mimeType;
    }
    var frameWidth = outVideo.frameWidth;
    var frameHeight = outVideo.frameHeight;
    // var qpSum_framesEncoded = outVideo.qpSum/outVideo.framesPerSecond
    var bytesSent = outVideo.bytesSent;
    var seconds = outVideo.framesEncoded / outVideo.framesPerSecond;
    var fps = outVideo.framesPerSecond;
    var raw_bitrate = (bytesSent * 8) / seconds;
    var bitrate = (raw_bitrate / 1000000).toFixed(2);
    var qosMsgUp =
      "send: " +
      "" +
      frameWidth +
      "x" +
      frameHeight +
      " fps: " +
      fps +
      " bitrate: " +
      bitrate +
      " Mbps " +
      outCodec;
    if (
      outVideo.qualityLimitationReason &&
      outVideo.qualityLimitationReason !== "none"
    ) {
      reason = outVideo.qualityLimitationReason;
      qosMsgUp += " " + reason;
    }
    // this.setState({ outbound_qos: qosMsgUp });

    var inCodec = "-";
    var inCodecObj = codecList.filter(
      (codec) => codec.id === inVideo.codecId
    )[0];
    if (inCodecObj !== undefined) {
      inCodec = inCodecObj.mimeType;
    }
    var iframeWidth = inVideo.frameWidth;
    var iframeHeight = inVideo.frameHeight;
    // var iqpSum_framesDecoded = inVideo.qpSum/inVideo.framesDecoded
    var bytesReceived = inVideo.bytesReceived;
    var iseconds = inVideo.framesReceived / inVideo.framesPerSecond;
    var ifps =
      inVideo.framesPerSecond || Math.round(inVideo.framesDecoded / iseconds);
    var iraw_bitrate = (bytesReceived * 8) / iseconds;
    var ibitrate = (iraw_bitrate / 1000000).toFixed(2);
    var qosMsgDown =
      "recv: " +
      "" +
      iframeWidth +
      "x" +
      iframeHeight +
      " fps: " +
      ifps +
      " bitrate: " +
      ibitrate +
      " Mbps " +
      inCodec;
    // this.setState({ inbound_qos: qosMsgDown });
    // this.setState({
    //   ipText:
    //     "remote: " +
    //     this.state.peer.remoteAddress +
    //     " local: " +
    //     this.state.peer.localAddress,
    // });

    var outQosJson = {
      frameWidth: frameWidth,
      frameHeight: frameHeight,
      fps: fps,
      bitrate: bitrate * 1000,
      reason: reason,
    };
    var inQosJson = {
      frameWidth: iframeWidth,
      frameHeight: iframeHeight,
      fps: ifps,
      bitrate: ibitrate * 1000,
      reason: "",
    };
    var referrer = document.referrer ?? "-";
    if (referrer === "") {
      referrer = "-";
    }
    var packetsLost = 0;
    var jitter = 0;
    var roundTripTime = 0;

    if (remoteInBoundRTP) {
      packetsLost = remoteInBoundRTP.packetsLost;
      jitter = remoteInBoundRTP.jitter;
      roundTripTime = remoteInBoundRTP.roundTripTime;
    }

    var qos = {
      roomId: this.state.roomId,
      username: this.state.myName,
      hn: this.state.hn,
      duration: Math.round(iseconds),
      out: outQosJson,
      in: inQosJson,
      outCodec: outCodec,
      inCodec: inCodec,
      packetsLost: packetsLost,
      jitter: jitter,
      roundTripTime: roundTripTime,
      project: "webrtc",
      referrer: referrer,
      // remoteIP: this.state.peer.remoteAddress ?? "-",
      // localIP: this.state.peer.localAddress,
    };

    if (!isMobile) {
      this.state.socket.emit("qos", qos);
    }
  };

  getStat = () => {
    setTimeout(() => {
      try {
        Object.keys(this.state.peers).forEach((key) => {
          let ppp = this.state.peers[key];
          ppp.getStats((err, items) => {
            var codecList = [];
            codecList = items.filter((item) => item.type === "codec");

            var outVideo = items.filter(
              (item) =>
                item.type === "outbound-rtp" && item.mediaType === "video"
            )[0];
            var inVideo = items.filter(
              (item) =>
                item.type === "inbound-rtp" && item.mediaType === "video"
            )[0];

            var remoteInBoundRTP = items.filter(
              (item) =>
                item.type === "remote-inbound-rtp" && item.kind === "video"
            )[0];

            if (isSafari) {
              outVideo = items.filter(
                (item) =>
                  item.type === "outbound-rtp" && item.mediaType === "video"
              )[0];
              inVideo = items.filter(
                (item) =>
                  item.type === "inbound-rtp" && item.mediaType === "video"
              )[0];
            }

            // console.log(outVideo)
            // console.log(inVideo)

            if (
              outVideo &&
              outVideo.timestamp !== undefined &&
              inVideo &&
              inVideo.timestamp !== undefined
            ) {
              this.calStat(inVideo, outVideo, codecList, remoteInBoundRTP);
            }
          });
        });

        if (this.state.isRec) this.getStat();
      } catch (e) {
        console.log("getStat", e);
      }
    }, 5000);
  };

  render() {
    return (
      <div
        style={
          !this.state.isGroupChat
            ? {
                width: "100%",
                height: "100%",
                overflow: "hidden",
                background: "#317a2a",
              }
            : {
              width: "100%",
              height: "100vh",
              overflow: "auto",
              overflowX: "hidden",
            }
        }
      >
        <div>
          <RatingPopup
            open={this.state.ratingPopupOpen}
            onClose={this.closeRatingPopup}
          />
        </div>

        {(this.state.isGroupChat || this.state.isShareScreen) && (
          <div className="flex-container">
            {this.state.isShareScreen && (
              <div className="flex-item-left">
                <Grid verticalAlign="middle" centered>
                  <Grid.Column>
                    <VideoItem
                      key={userId}
                      userId={userId}
                      stream={this.state.shareScreenStream}
                      style={{ height: "100%", width: "100%" }}
                      muted={true}
                      controls={true}
                    />
                  </Grid.Column>
                </Grid>
              </div>
            )}
            <div className="flex-item-right">
              <Grid
                verticalAlign="top"
                stackable={this.state.stackable}
                container={isMobile ? false : true}
                columns={
                  this.state.isShareScreen
                    ? "1"
                    : this.state.columnSize.toString()
                }
                style={{
                  height: "100vh",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <Grid.Row stretched>
                  {Object.keys(this.state.peers).map((userId, id) => (
                    <Grid.Column key={userId} className="mycolumn">
                      {
                        this.state.peers[userId].remoteStream
                          .filter((item) => item.active === true)
                          .map((item) => (
                            <>
                              <VideoItem
                                key={item.id}
                                userId={userId}
                                stream={item}
                                style={
                                  isMobile
                                    ? {
                                        height: window.innerHeight / 3,
                                        width: "100%",
                                      }
                                    : {
                                        height:
                                          window.innerHeight /
                                            this.state.columnSize -
                                          20,
                                        width: "100%",
                                      }
                                }
                                controls={false}
                              />
                              <Label
                                as="a"
                                color="black"
                                attached="top left"
                                style={{ marginLeft: "14px", top: "14px" }}
                              >
                                {this.state.remotePeerName[userId]}
                              </Label>
                            </>
                          ))
                        // <div className="" style={{ minWidth: "160px" }}>
                        //   <Image
                        //     fluid
                        //     key={userId}
                        //     label={{
                        //       as: "a",
                        //       color: "red",
                        //       corner: "right",
                        //       icon: "save",
                        //     }}
                        //     src="https://react.semantic-ui.com/images/wireframe/image.png"
                        //   />
                        // </div>
                      }
                      <Card.Content>
                        <Card.Meta
                          style={{ color: "white", fontSize: "0.8em" }}
                        >
                          {/* {this.state.remotePeerName[userId]} */}
                          {this.state.geekStat && (
                            <p style={{ color: "white", fontSize: "0.5em" }}>
                              {this.state.peers[userId].localAddress} {"<=>"}{" "}
                              {this.state.peers[userId].remoteAddress}
                            </p>
                          )}
                        </Card.Meta>
                      </Card.Content>
                    </Grid.Column>
                  ))}
                  <Grid.Column className="mycolumn">
                    {/* <Image
                      fluid
                      label={{
                        as: "a",
                        color: "red",
                        corner: "right",
                        icon: "heart",
                      }}
                      src="https://react.semantic-ui.com/images/wireframe/image.png"
                    /> */}
                    <VideoItem
                      key={userId}
                      userId={userId}
                      stream={this.state.localStream}
                      muted={true}
                      style={
                        isMobile
                          ? { height: window.innerHeight / 3, width: "100%" }
                          : {
                              height:
                                window.innerHeight / this.state.columnSize - 20,
                              width: "100%",
                            }
                      }
                      controls={false}
                    />

                    <Card.Content>
                      <Card.Meta style={{ color: "white", fontSize: "0.8em" }}>
                        {/* {this.state.myName} */}
                      </Card.Meta>
                    </Card.Content>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}

        {!(this.state.isGroupChat || this.state.isShareScreen) && (
          <div className="video-wrapper">
            <Grid
              doubling
              verticalAlign="middle"
              columns={this.state.columnSize.toString()}
              centered
              style={{ height: "100%", width: "100%" }}
            >
              {Object.keys(this.state.peers).map((userId, id) => (
                <Grid.Column key={userId}>
                  {this.state.peers[userId].remoteStream
                    .filter((item) => item.active === true)
                    .map((item) => (
                      <div
                        className="remoteVideo"
                        style={{
                          minWidth: "320px",
                          minHeight: "240px",
                          height: "90vh",
                        }}
                        onClick={() => {
                          let peer = this.state.peers[userId];
                          console.log(peer);
                          peer.signal({ renegotiate: true });
                          this.state.socket.emit("signal", {
                            type: "renegotiate",
                            renegotiate: true,
                          });
                        }}
                      >
                        <VideoItem
                          key={item.id}
                          userId={userId}
                          stream={item}
                          style={{ height: "100%", width: "100%" }}
                          controls={false}
                        />
                        <Label
                          as="a"
                          color="black"
                          attached="top left"
                          style={{ marginLeft: "14px", top: "14px" }}
                        >
                          {this.state.remotePeerName[userId]}
                        </Label>
                      </div>
                    ))}
                  <Card.Content>
                    <Card.Meta style={{ color: "white", fontSize: "1.0em" }}>
                      {this.state.geekStat && (
                        <p style={{ color: "white", fontSize: "0.8em" }}>
                          {this.state.peers[userId].localAddress} {"<=>"}{" "}
                          {this.state.peers[userId].remoteAddress}
                        </p>
                      )}
                    </Card.Meta>
                  </Card.Content>
                </Grid.Column>
              ))}
            </Grid>
            <div className="local-video-wrapper">
              <div id="localVideo">
                <VideoItem
                  key={userId}
                  userId={userId}
                  autoPlay
                  playsInline
                  crossOrigin="true"
                  stream={this.state.localStream}
                  muted={true}
                  controls={false}
                  style={{ maxHeight: "240px", width: "100%" }}
                />
                {/* <div style={{ color: "white", fontSize: "1.0em", left: "0px" }}>
                  {this.state.myName}
                </div> */}
              </div>
            </div>
          </div>
        )}

        <div>
          {/* <Button
            style={{ position: "absolute", bottom: "48px", right: "132px" }}
            onClick={() => {
              this.setState({isGroupChat: !this.state.isGroupChat})
            }}
          >
            <Icon name="wifi" color={this.state.isGroupChat ? "green" : "red"} />
            gr...
          </Button>
          <Button
            style={{ position: "absolute", bottom: "48px", right: "40px" }}
            onClick={() => {
              this.setState({isShareScreen: !this.state.isShareScreen})
            }}
          >
            <Icon name="wifi" color={this.state.isShareScreen ? "green" : "red"} />
            ss
          </Button> */}

          {this.state.geekStat && (
            <>
              <div
                style={{ position: "absolute", top: "180px", right: "24px" }}
                // onClick={() => {
                //   this.setState({ geekStat: !this.state.geekStat });
                // }}
              >
                {this.state.connecting && (
                  <div className="status">
                    <p>กำลังเชื่อมต่อ</p>
                  </div>
                )}
                {this.state.waiting && (
                  <div className="status">
                    <p>กรุณารอสักครู่</p>
                  </div>
                )}
                {this.state.connected && (
                  <div className="status">
                    <p>เชื่อมต่อแล้ว</p>
                    {userId}
                  </div>
                )}
              </div>
              <Icon
                name="record"
                style={{ position: "absolute", bottom: "24px", right: "24px" }}
                color={this.state.isRec ? "green" : "red"}
              />
              <div
                className="status"
                style={{ position: "absolute", bottom: "4px", right: "24px" }}
              >
                <p>{this.state.recordMimeType}</p>
              </div>
              <div
                className="status"
                style={{ position: "absolute", bottom: "42px", left: "4px" }}
              >
                <p>{this.state.ipText}</p>
              </div>
              <div
                className="status"
                style={{ position: "absolute", bottom: "22px", left: "4px" }}
              >
                <p>{this.state.inbound_qos}</p>
              </div>
              <div
                className="status"
                style={{ position: "absolute", bottom: "4px", left: "4px" }}
              >
                <p>{this.state.outbound_qos}</p>
              </div>
            </>
          )}

          <Transition.Group animation={"browse"} duration={500}>
            {this.state.showerr && (
              <div
                className="ui negative message"
                style={{ position: "absolute", width: "50%", top: "24px" }}
              >
                <div className="header">{this.state.errHeader}</div>
                <p>{this.state.errMsg}</p>
              </div>
            )}
          </Transition.Group>
        </div>

        <div className="media-bar-container">
          <div className="media-bar">
            <Button
              circular
              icon="external alternate"
              size="large"
              color={this.state.pipOn ? "blue" : "grey"}
              disabled={this.state.pipOn || this.state.closeOverriding}
              onClick={() => {
                if (this.state.pipOn) {
                  this.stopPip();
                } else {
                  this.startPip();
                }
              }}
            ></Button>
            <Button
              circular
              icon="computer"
              size="large"
              color={this.state.shareScreenOn ? "blue" : "grey"}
              disabled={this.state.closeOverriding}
              onClick={() => {
                if (this.state.shareScreenOn) {
                  this.stopShareScreen();
                } else {
                  this.getShareScreen();
                }
              }}
            ></Button>
            <Button
              circular
              icon="microphone"
              size="large"
              color={this.state.micState ? "grey" : "red"}
              loading={this.state.micLoading}
              disabled={this.state.micLoading || !this.state.connected}
              onClick={() => {
                this.setAudioLocal();
              }}
            ></Button>
            <Button
              circular
              icon="video"
              size="large"
              color={this.state.camState ? "grey" : "red"}
              loading={this.state.webcamLoading}
              disabled={this.state.webcamLoading || !this.state.connected}
              onClick={() => {
                this.setVideoLocal();
              }}
            ></Button>
            <Button
              circular
              icon="call"
              color="red"
              size="large"
              onClick={() => {
                console.log("End call");
                const users_count = Object.keys(this.state.peers).length;
                if (!this.state.closeOverriding) {
                  // this.setState({ ratingPopupOpen: true });
                  const users_count = Object.keys(this.state.peers).length;
                  window.top.postMessage(
                    JSON.stringify({
                      error: false,
                      users_count: users_count,
                      message: "end_call",
                    }),
                    "*"
                  );
                  window.onbeforeunload = null;
                  window.open("about:blank", "_self");
                  window.close();
                } else {
                  // this.setState({ ratingPopupOpen: true });
                  window.top.postMessage(
                    JSON.stringify({
                      error: false,
                      users_count: users_count,
                      message: "closeOverriding",
                    }),
                    "*"
                  );
                }
              }}
            ></Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
